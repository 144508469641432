

@font-face {
  font-family:'Montserrat';
  src:url('./font/Montserrat-Regular.otf') format('opentype');
  font-style:normal;
  font-weight:normal;
}

font-face {
  font-family:'Montserrat';
  src:url('./font/Montserrat-Bold.otf') format('opentype');
  font-style:normal;
  font-weight:bold;
}

font-face {
  font-family:'Montserrat';
  src:url('./font/Montserrat-Thin.otf') format('opentype');
  font-style:normal;
  font-weight:'100';
}

html {
  overflow-x:hidden; 
  font-family: 'Montserrat'
}

body{
  font-family: 'Montserrat'
}


.footer {
  background: 5da6db; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, #5da6db, #7556a4); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #5da6db, #7556a4); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #5da6db, #7556a4); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #5da6db, #7556a4); /* Standard syntax */
  background-size: cover;
}

.promo {
  clip-path: polygon(0 0, 1600px 0, 1600px 70%, 0 100%);
}

.promo2 {
  clip-path: polygon(0 24%, 1600px 0, 1600px 73%, 0 100%);
}

.promo3 {
  clip-path: polygon(0 31%, 1600px 0, 1600px 87%, 0 90.5%);
}

.hero {
  color: #fff;
  font-family: 'Fira Sans', sans-serif;
  position: relative;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0,0,0,0.5);
  
  img {
    width: 100%;
  }
  
  figcaption {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  
  h1 {
    font-size: 32px;
  }
  
  p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 0.5em;
  }
}

.promo {
  /*background: url(https://unsplash.it/1600/900?image=830) no-repeat;*/
  background: red; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, #3ba1dc, #673aa7); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #3ba1dc, #673aa7); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #3ba1dc, #673aa7); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #3ba1dc, #673aa7); /* Standard syntax */
  background-size: cover;
  color: #fff;
  font-family: 'Montserrat';
  margin: 0px 0;
  padding: 150px 20px;
  position: relative;
  text-align: center;
  height: 600px;
   display: flex;
   flex-direction: column;
   justify-content:flex-start;
   align-items: center;
   z-index: 33;
  
  h1 {
    font-size: 32px;
  }
  
  p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 0.5em;
  }
 
}
.promo2 {
  /*background: url(https://unsplash.it/1600/900?image=830) no-repeat;*/
  background: red; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, #3ba1dc, #673aa7); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #3ba1dc, #673aa7); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #3ba1dc, #673aa7); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #3ba1dc, #673aa7); /* Standard syntax */
  background-size: cover;
  color: #fff;
  font-family: 'Montserrat';  
  margin: 0px 0;
  padding: 150px 20px;
  position: relative;
  text-align: center;
  height: 700px;
   display: flex;
   flex-direction: column;
   justify-content:flex-start;
   align-items: center;
   z-index: 33;
  
  h1 {
    font-size: 32px;
  }
  
  p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 0.5em;
  }

}

.promo3 {
  /*background: url(https://unsplash.it/1600/900?image=830) no-repeat;*/
  background: red; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, #3ba1dc, #673aa7); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #3ba1dc, #673aa7); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #3ba1dc, #673aa7); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #3ba1dc, #673aa7); /* Standard syntax */
  background-size: cover;
  color: #fff;
  font-family: 'Montserrat';
    margin: 0px 0;
  padding: 150px 20px;
  position: relative;
  text-align: center;
  height: 600px;
   display: flex;
   flex-direction: column;
   justify-content:flex-start;
   align-items: center;
   z-index: 33;
  
  h1 {
    font-size: 32px;
  }
  
  p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 0.5em;
  }

}

.header {
   display: flex;
   flex-direction: row;
   width: 90%;
   height: 50px;
   margin-top: 30px;
   z-index: 99;
   align-items: space-between;
   justify-content:space-between;
}

.header2 {
   display: flex;
   flex-direction: column;
   width: 90%;
   height: 50px;
   margin-top: 30px;
   z-index: 99;
   align-items: space-between;
   justify-content:space-between;
}